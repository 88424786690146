import { Box } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Application,
  Header,
  Logo,
  SettingsAccountBoxIcon,
  UserMenu,
} from '..';
import partner from '../../assets/abaco.png';
import { Divider } from '../DefaultApplication';
import { NavigationItem } from '../DefaultApplication/NavigationItem';
import NewCityButton from '../NewCityButton';

export interface HomeApplicationProps {
  browserTitle?: string;
}

const Image = styled.img({
  maxWidth: 170,
  width: '100%',
});

const SettingsLink = styled(SettingsAccountBoxIcon)({
  position: 'relative',
  width: 28,
  height: 28,
  display: 'block',
});

const HomeApplication: FC<HomeApplicationProps> = ({
  children,
  browserTitle,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {!_.isNil(browserTitle) && <Helmet title={browserTitle} />}

      <Application
        header={
          <Header
            leftContent={
              <>
                <Box>
                  <Logo clickable onClick={() => navigate('/')} />
                </Box>
                <Box pr={30} pl={40}>
                  <Divider />
                </Box>
                <Image src={partner} alt="partner" />
              </>
            }
            rightContent={
              <>
                <NavigationItem
                  link={`/configura`}
                  label={<SettingsLink />}
                  isIcon
                />
                <Box mr="24px" ml="32px">
                  <NewCityButton />
                </Box>
                <Box>
                  <UserMenu />
                </Box>
              </>
            }
          />
        }
      >
        {children}
      </Application>
    </>
  );
};

export default HomeApplication;
